import request from './request.js';
import public_request from './public_request'
//获取用户数据列表
export function list({ pageNo, pageSize }) {
    return request({
        url: '/user-management/organizations',
        method: 'get',
        params: public_request({
            pageNo,
            pageSize
        }),
        headers: {
            authorization: sessionStorage.getItem("token"),
        },
    })
}
//添加用户
export function add(data) {
    return request({
        url: '/user-management/organization',
        method: 'post',
        data: public_request(data)
    })
}
//编辑用户
export function edit(data) {
    return request({
        url: '/user-management/organization',
        method: 'PUT',
        data: public_request(data)
    })
}
//删除用户
export function remove({ userOrganizationId }) {
    return request({
        url: '/user-management/organization',
        method: 'DELETE',
        data: public_request({
            userOrganizationId
        })
    })
}
//修改组织用户登录名前缀
export function editloginPrefix({ userOrganizationId, loginPrefix }) {
    return request({
        url: '/user-management/organization-login-prefix',
        method: 'PUT',
        data: public_request({
            userOrganizationId,
            loginPrefix
        })
    })
}
//修改组织用户启用状态
export function editEnable({ userOrganizationId, enable }) {
    return request({
        url: '/user-management/organization-enable',
        method: 'PUT',
        data: public_request({
            userOrganizationId,
            enable
        })
    })
}
//重置密码
export function resetMm({ userOrganizationId }) {
    return request({
        url: '/user-management/organization-password',
        method: 'PUT',
        data: public_request({
            userOrganizationId
        })
    })
}

// 获取权限列表
export function getRoleList(data) {

    return request({
        url: '/user-management/organization/permissions-tree',
        method: 'GET',
        params: public_request(data)
    })
}


// 添加权限管理
export function setRole(data) {
    console.log(data);
    return request({
        url: '/user-management/organization/permissions-tree',
        method: 'PUT',
        data: public_request(data)
    })
}

//获取用户反馈列表
export function userFeedback(data) {

    return request({
        url: '/user-feedback/feedbacks',
        method: 'GET',
        params: public_request(data)
    })
}

//获取应用列表
export function userList(data) {

    return request({
        url: '/application-management/applications',
        method: 'GET',
        params: public_request(data)
    })
}