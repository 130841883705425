<template>
  <div class="boby">
    <h3 style="text-align: center; margin-bottom: 20px; margin-top: 20px">
      用户反馈
    </h3>

    <el-card style="margin-left: 20px; margin-right: 20px">
      <div class="selectOption">
        <el-select
          v-model="applicationId"
          placeholder="请选择"
          @change="getList()"
        >
          <el-option
            v-for="item in options"
            :key="item.name"
            :label="item.name"
            :value="item.id"
          />
        </el-select>

        <div class="block">
          <el-date-picker
            v-model="value1"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            :picker-options="pickerOptions"
            @change="checkDate"
          />
        </div>
      </div>
      <div class="usertable">
        <el-table
          v-tableHeight="{bottomOffset: 90}"
          height="100px"
          :data="tableData"
          border
          style="width: 100%"
          :row-style="{height: '0'}"
          :cell-style="{padding: '0'}"
        >
          <el-table-column
            prop="id"
            min-width="10"
            label="序号"
          />
          <el-table-column
            prop="username"
            min-width="10"
            label="用户名"
          />
          <el-table-column
            prop="content"
            label="反馈内容"
          />
          <el-table-column
            prop="createdTime"
            min-width="15"
            label="反馈时间"
          />
        </el-table>
      </div>
      <div
        class="block"
        style="margin-top: 10px; text-align: right"
      >
        <el-pagination
          :current-page="page"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="len"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </el-card>
  </div>
</template>
<script>
import { userFeedback, userList } from "../../../src/api/user";

import Moment from "moment";
export default {
  data() {
    return {
      loading: false,
      value1: "",
      filterTableData: [],
      startTime: "",
      endTime: "",
      pickerOptions: {
        //  disabledDate(time) {
        //   return time.getTime() > Date.now() - 8.64e6
        // }
      },
      options: [
        {
          value: "",
          label: "",
        },
      ],

      tableData: [],
      value: "",
      value2: "",
      currentPage4: 4,
      applicationId: "",
      page: 1,
      size: 10,
      len: 0,
    };
  },

  mounted() {
    // this.getList();
    this.getuserList();
  },

  methods: {
    checkDate() {
      console.log(this.value1);
      if (this.value1 == null) {
        this.startTime = "";
        this.endTime = "";
      } else {
        let startTime = Moment(this.value1[0]).unix();
        this.startTime = startTime;
        let endTime = Moment(this.value1[1]).unix();
        this.endTime = endTime;
      }

      this.getList();
    },

    getuserList() {
      userList().then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.options = res.data.list;
          this.applicationId = this.options[0].id;
          this.getList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    dateTime(ts) {
      const stamp = new Date(ts);
      const time = Moment(stamp).format("YYYY-MM-DD HH:mm:ss");
      return time;
    },

    getList() {
      let search = {
        applicationId: this.applicationId,
        pageNo: this.page,
        pageSize: this.size,
        startTime: this.startTime,
        endTime: this.endTime,
      };
      userFeedback(search).then((res) => {
        console.log(search);
        console.log(res);
        if (res.code == 0) {
          this.tableData = res.data.list;
          this.tableData.forEach((v) => {
            v.createdTime = this.dateTime(v.createdTime * 1000);
          });
          // this.filterTableData = this.tableData
          this.len = res.data.total;
        } else {
          this.$message.error(res.message);
        }
      });
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.getList();
    },
  },
};
</script>
<style  lang="less" scoped>
/deep/ .el-table th {
  background: rgb(245, 247, 250);
}
/deep/ .el-table .cell {
  padding: 5px;
}
.selectOption {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>